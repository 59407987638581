<template>
  <div>
    <filters
      class="mb-4"
      :filters="filters"
      :errors="errors"
      @change="onFilterChange($event)"
    />
    <group-by
      :group-by="filters.groupBy"
      :types="sspStatisticsGroupByOptions"
      @change="onFilterChange($event)"
    />
    <statistics-table
      :statistics="statistics"
      :selected-group-by-option="selectedGroupByOption"
      :is-loading="isLoading"
    />
  </div>
</template>

<script>
  import moment from 'moment'
  import debounce from 'debounce'
  import GroupBy from './GroupBy.vue'
  import StatisticsTable from './StatisticsTable.vue'
  import Filters from './Filters.vue'
  import { sspStatisticsRepository } from '@/services/repository-factory.js'
  import handleErrors from '@/services/handleErrors.js'

  const headersLabels = {
    period: 'Date',
    country: 'Country',
    source_id: 'Source ID'
  }

  export default {
    name: 'SspStatistics',
    components: {
      StatisticsTable,
      GroupBy,
      Filters
    },
    data() {
      return {
        isLoading: false,
        statistics: [],
        errors: {},
        debouncedFetchStatistics: null,
        filters: {
          dates: {
            start: moment().add({ days: -14 }).format('YYYY-MM-DD'),
            end: moment().format('YYYY-MM-DD')
          },
          groupBy: 'period'
        }
      }
    },
    computed: {
      sspStatisticsGroupByOptions() {
        return [
          {
            value: 'period',
            label: this.$t('rtb.statistics.dates'),
            headers: ['date', 'impressions', 'clicks', 'supply_side_platform_cost']
          },
          {
            value: 'country',
            label: this.$t('rtb.statistics.country'),
            headers: ['country', 'impressions', 'clicks', 'supply_side_platform_cost']
          },
          {
            value: 'source_id',
            label: this.$t('rtb.statistics.source_id'),
            headers: ['source_id', 'impressions', 'clicks', 'supply_side_platform_cost']
          }
        ]
      },
      selectedGroupByOption() {
        return this.sspStatisticsGroupByOptions.find((option) => option.value === this.filters.groupBy).headers.reduce((acc, headerKey) => {
          acc.push({
            text: headersLabels[headerKey],
            value: headerKey
          })
          return acc
        }, [])
      }
    },
    created() {
      this.debouncedFetchStatistics = debounce(this.fetchStatistics, 500)
      this.debouncedFetchStatistics()
    },
    methods: {
      async fetchStatistics() {
        this.isLoading = true
        try {
          const { items } = await sspStatisticsRepository.list(this.filters)
          this.statistics = items ?? []
        } catch (error) {
          this.errors = handleErrors(error)
        }
        this.isLoading = false
      },
      onFilterChange({ key, value }) {
        this.filters = { ...this.filters, [key]: value }
        this.debouncedFetchStatistics()
      }
    }
  }
</script>
