<template>
  <v-card :loading="isLoading" elevation="0">
    <v-card-title class="text-body-1 black white--text font-weight-medium py-3 pl-6">
      {{ $t('rtb.statistics.statistics_settings') }}
    </v-card-title>
    <v-card-text class="py-0">
      <v-container fluid class="px-0">
        <v-row align="center">
          <v-col cols="12" md="4" class="align-self-start">
            <date-picker-select
              :value="filters.dates"
              with-presets
              :text-field-props="{
                errorMessages: errors.start
              }"
              @input="onFilterChange({ key: 'dates', value: $event })"
            />
          </v-col>
          <v-col cols="12" md="4">
            <c-autocomplete
              :value="filters.supplySidePlatforms"
              :items="supplySidePlatforms"
              :loading="isLoading"
              item-text="uuid"
              item-value="id"
              label="SSP UUID"
              placeholder="SSP UUID"
              clearable
              multiple
              :error-messages="errors.supplySidePlatforms"
              @change="onFilterChange({ key: 'supplySidePlatforms', value: $event })"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
  import DatePickerSelect from '@clickadilla/components/DatePickerSelect/index.vue'
  import CAutocomplete from '@clickadilla/components/ui/CAutocomplete.vue'
  import { sspStatisticsRepository } from '@/services/repository-factory.js'
  import handleErrors from '@/services/handleErrors.js'

  export default {
    name: 'Filters',
    components: {
      DatePickerSelect,
      CAutocomplete
    },
    props: {
      filters: {
        type: Object,
        required: true
      },
      errors: {
        type: Object,
        default: () => ({})
      }
    },
    data() {
      return {
        isLoading: false,
        supplySidePlatforms: []
      }
    },
    created() {
      this.fetchSupplySidePlatforms()
    },
    methods: {
      async fetchSupplySidePlatforms() {
        this.isLoading = true
        try {
          this.supplySidePlatforms = await sspStatisticsRepository.supplySidePlatforms()
        } catch (error) {
          handleErrors(error)
        }
        this.isLoading = false
      },
      onFilterChange(payload) {
        this.$emit('change', payload)
      }
    }
  }
</script>
