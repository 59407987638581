<template>
  <tr class="info white--text">
    <th>
      <span class="text-body-2 font-weight-bold">{{ $t('main.total') }}</span>
    </th>
    <th v-for="header in totalHeaders" :key="header.value" class="text-body-2 font-weight-bold">
      {{ fixedTotals(header) }}
    </th>
  </tr>
</template>

<script>
  export default {
    name: 'TotalStatistics',
    props: {
      headers: {
        type: Array,
        default: () => []
      },
      items: {
        type: Object,
        default: () => {}
      },
      hasCheckboxes: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      totalHeaders() {
        const noTitleRow = this.headers.slice(1)
        return this.hasCheckboxes ? [...[{}], ...noTitleRow] : noTitleRow
      }
    },
    methods: {
      fixedTotals(header) {
        return this.items[header.value] ?? ''
      }
    }
  }
</script>
