<template>
  <v-card elevation="0">
    <v-data-table
      :loading="isLoading"
      :headers="headers"
      :options.sync="options"
      :items="statistics"
      hide-default-footer
      @page-count="pageCount = $event"
    >
      <template v-if="statistics.length" #body.append>
        <total-statistics v-if="!isMobile" :headers="headers" :items="totalStatistics" />
      </template>
      <template #footer>
        <data-table-footer
          :items-per-page="options.itemsPerPage"
          :current-page="options.page"
          :page-count="pageCount"
          @change-items-per-page="changeItemsPerPage($event)"
          @change-current-page="changeCurrentPage($event)"
        />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
  import DataTableFooter from '@clickadilla/components/ui/DataTableFooter.vue'
  import TotalStatistics from '@/components/TotalStatistics.vue'
  import calculateTotalStatistics from '@/services/utils/calculate-total-statistics.js'

  export default {
    name: 'StatisticsTable',
    components: { TotalStatistics, DataTableFooter },
    props: {
      isLoading: {
        type: Boolean,
        default: false
      },
      statistics: {
        type: Array,
        required: true
      },
      selectedGroupByOption: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        pageCount: 0,
        options: {
          page: 1,
          itemsPerPage: 15
        }
      }
    },
    computed: {
      headers() {
        return this.selectedGroupByOption.map(({ value }) => ({
          value,
          text: this.$t(`rtb.statistics.table.${value}`)
        }))
      },
      totalStatistics() {
        return calculateTotalStatistics(this.statistics, {
          supply_side_platform_cost: 'amount',
          impressions: 'amount',
          clicks: 'amount'
        })
      },
      isMobile() {
        return this.$vuetify.breakpoint.xs
      }
    },
    watch: {
      statistics() {
        this.options.page = 1
      }
    },
    methods: {
      changeCurrentPage(event) {
        this.options.page = event
      },
      changeItemsPerPage(event) {
        this.options.itemsPerPage = event
        this.options.page = 1
      }
    }
  }
</script>
