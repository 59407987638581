<template>
  <v-tabs
    :value="groupBy"
    show-arrows
    prev-icon="$arrow-left"
    next-icon="$arrow-right"
    grow
    height="32"
  >
    <c-btn
      v-for="item in types"
      :key="item.value"
      :label="item.label"
      :class="[
        'ml-1 black--text font-weight-regular',
        { 'primary white--text': item.value === groupBy }
      ]"
      height="32"
      depressed
      @click="changeGroupBy(item.value)"
    />
  </v-tabs>
</template>

<script>
  import CBtn from '@clickadilla/components/ui/CBtn.vue'

  export default {
    name: 'GroupBy',
    components: {
      CBtn
    },
    props: {
      groupBy: {
        type: String,
        required: true
      },
      types: {
        type: Array,
        required: true
      }
    },
    methods: {
      changeGroupBy(groupBy) {
        this.$emit('change', { key: 'groupBy', value: groupBy })
      }
    }
  }
</script>
